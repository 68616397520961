import '../css/Book.css';
import Navbar from '../bars/Navbar';
import Footer from '../bars/Footer';
import icon from '../images/logos/newtab.png';
import tatt from '../images/tatt.png';

function Book() {

    return (
        <div className='Book'>
            <Navbar />
            <img className='Book-img' src={tatt} alt='tattoo' />
            <div className='Book-body'>
                <div className='Book-link'>
                    {/* <a className='Book-a' href='https://forms.gle/v2qPThwvKYLKnMqC9' rel="noreferrer" target='_blank'>OCTOBER 2024 NYC</a> */}
                    January & February Books coming soon
                    <img className='Book-icon' src={icon} alt='new tab' />
                </div>
                {/* <div className='Book-link'>
                    <a className='Book-a' href='https://forms.gle/MiDcvoXDUjc6LjsA8' rel="noreferrer" target='_blank'>touchup form</a>
                    <img className='Book-icon' src={icon} alt='new tab' />
                </div> */}
                <br />
                <div className='Book-link'>
                    <b>not in nyc? fill out my</b> <a href='https://forms.gle/K7Jcvo3F4p1Dcp2Q7' rel="noreferrer" target='_blank'>&nbsp;waitlist form&nbsp;</a> <b>to receive a notification if I come to your city!</b>
                </div>
            </div>
            <Footer />
        </div >
    );
}

export default Book;
